import { UserDto } from '@aksorn/one-account-sdk';
import get from 'lodash/get';

export const getUserDisplayName = (user: UserDto | undefined, i18n: any) => {
  if (!user) return '';

  return `${i18n.language === 'th' ? user.title : ''}${get(
    user.firstName,
    `${i18n.language}`,
    ''
  )} ${get(user.lastName, `${i18n.language}`, '')}`;
};
