/** @jsx jsx */

import {
  ClassPeriodListDto,
  ClassPeriodModifyLicenseUsageDto,
  ClassPeriodModifyResponse,
  ClassPeriodScheduleDto,
  SchoolTeacherDto
} from '@aksorn/teaching-backoffice-sdk';
import {
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Spin,
  TimePicker,
  message
} from 'antd';
import { DayOfWeek, DayOfWeekMapper, getCover } from 'feature/common/global';
import { FormRule, getRule } from 'utils/getRule';
import { Global, css, jsx } from '@emotion/react';
import {
  PrimaryButton,
  SecondaryButton
} from 'feature/common/components/Button';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useMemo, useState } from 'react';
import {
  useBackEndClassPeriod,
  useClassPeriod
} from 'hooks/class-period/useClassPeriod';

import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { Fragment } from 'react';
import { ReactComponent as LogoIcon } from 'assets/logo.svg';
import { MANAGE_CLASS_PERIOD_ERROR_MESSAGE_MAPPER } from './const';
import { Modal } from 'feature/common/components/Modal';
import { PlusCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { map } from 'lodash';
import moment from 'moment';
import { useCreateClassPeriod } from 'hooks/class-period/useCreateClassPeriod';
import { useDeleteClassPeriod } from 'hooks/class-period/useDeleteClassPeriod';
import { useHistory } from 'react-router-dom';
import { useSchoolTeachers } from 'hooks/school-teacher/useSchoolTeachers';
import { useSubjects } from 'hooks/subject/useSubjects';
import { useUpdateClassPeriod } from 'hooks/class-period/useUpdateClassPeriod';

const { Option } = Select;

interface ClassPeriodFormProps {
  classroomId: string;
  classPeriodId?: string;
  classPeriods: ClassPeriodListDto[];
  onClose: () => void;
  onNext: ({
    classPeriodLicenseUsages,
    error
  }: {
    classPeriodLicenseUsages: ClassPeriodModifyLicenseUsageDto[];
    error?: ClassPeriodModifyResponse;
  }) => void;
  mainSchoolTeacher?: string;
}

export const ClassPeriodForm = ({
  classroomId,
  classPeriods,
  classPeriodId,
  onClose,
  onNext,
  mainSchoolTeacher
}: ClassPeriodFormProps) => {
  const [classPeriodForm] = Form.useForm();

  const history = useHistory();
  const [
    selectedClassPeriodTeachers,
    setSelectedClassPeriodTeachers
  ] = useState<SchoolTeacherDto[]>([]);

  const [mainSchoolTeacherId, setMainSchoolTeacherId] = useState('');
  const [
    isDeleteClassPeriodModalVisible,
    setIsDeleteClassPeriodModalVisible
  ] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(
    false
  );

  const [coverUrl, setCoverUrl] = useState('');
  const [schoolTeacherCurrentPage, setSchoolTeacherCurrentPage] = useState(1);
  const [tmpSchoolTeachers, setTmpSchoolTeachers] = useState<
    SchoolTeacherDto[]
  >([]);
  const [schoolTeachers, setSchoolTeachers] = useState<SchoolTeacherDto[]>([]);
  const schoolTeacherPageSize = 100;
  const [
    isLoadSchoolTeacherComplete,
    setIsLoadSchoolTeacherComplete
  ] = useState(false);

  const {
    total: schoolTeacherTotal,
    data: schoolTeacher,
    loading: getSchoolTeachersLoading,
    execute: getSchoolTeachers
  } = useSchoolTeachers();

  const {
    loading: creating,
    execute: createClassPeriod
  } = useCreateClassPeriod();

  // const {
  //   data: classPeriod,
  //   loading: getClassPeriodLoading,
  //   execute: getClassPeriod
  // } = useClassPeriod();
  const {
    data: classPeriod,
    loading: getClassPeriodLoading,
    execute: getBackEndClassPeriod
  } = useBackEndClassPeriod();

  const {
    loading: updating,
    execute: updateClassPeriod
  } = useUpdateClassPeriod();

  const {
    data: subjects,
    loading: getSubjectsLoading,
    execute: getSubjects
  } = useSubjects();

  const {
    loading: deleting,
    execute: deleteClassPeriod
  } = useDeleteClassPeriod();

  useEffect(() => {
    // // unlock mainSchoolTeacher 19/08/2022
    // getSchoolTeachers({
    //   pageNumber: schoolTeacherCurrentPage,
    //   pageSize: schoolTeacherPageSize
    // });
    getSchoolTeachers({
      pageNumber: schoolTeacherCurrentPage,
      pageSize: schoolTeacherPageSize,
      mainSchoolTeacher: mainSchoolTeacher
    });
    getSubjects();
  }, []);

  useEffect(() => {
    if (schoolTeacherTotal > 0) {
      setTmpSchoolTeachers([...tmpSchoolTeachers, ...schoolTeacher]);

      if (
        schoolTeacherCurrentPage * schoolTeacherPageSize <
        schoolTeacherTotal
      ) {
        setSchoolTeacherCurrentPage(schoolTeacherCurrentPage + 1);
        getSchoolTeachers({
          pageNumber: schoolTeacherCurrentPage + 1,
          pageSize: schoolTeacherPageSize
        });
      } else {
        setIsLoadSchoolTeacherComplete(true);
      }
    }
  }, [schoolTeacher]);

  useEffect(() => {
    setSchoolTeachers([...schoolTeachers, ...tmpSchoolTeachers]);
  }, [isLoadSchoolTeacherComplete]);

  useEffect(() => {
    if (classPeriod?.mainSchoolTeacherId) {
      const classTeacherIds = classPeriod?.classPeriodTeachers?.map(
        ({ schoolTeacherId }) => schoolTeacherId
      );
      const classTeacher = schoolTeachers?.filter(({ schoolTeacherId }) =>
        classTeacherIds?.includes(schoolTeacherId)
      );
      setSelectedClassPeriodTeachers(classTeacher);
    }
  }, [schoolTeachers, classPeriod]);

  useEffect(() => {
    if (classPeriodId) {
      // getClassPeriod(classPeriodId);
      getBackEndClassPeriod(classPeriodId);
    }
  }, [classPeriodId]);

  useEffect(() => {
    let subjectName = '';
    if (classPeriod) {
      const classSchedules = classPeriod?.classPeriodSchedules?.map(
        (classPeriodSchedule) => ({
          ...classPeriodSchedule,
          startTime: moment(classPeriodSchedule?.startTime, 'HH:mm'),
          endTime: moment(classPeriodSchedule?.endTime, 'HH:mm')
        })
      );
      setMainSchoolTeacherId(classPeriod?.mainSchoolTeacherId);
      classPeriodForm.setFieldsValue({
        subjectId: classPeriod.subject?.subjectId,
        classPeriodName: classPeriod?.classPeriodName,
        mainSchoolTeacherId: classPeriod?.mainSchoolTeacherId,
        classPeriodSchedules: classSchedules
      });

      subjectName =
        subjects.find(
          (subject) => subject.subjectId === classPeriod.subject?.subjectId
        )?.name.th ?? '';
    }

    const coverUrl = getCover(subjectName);
    setCoverUrl(coverUrl);
  }, [classPeriod]);

  const nonClassPeriodSchoolTeachers = useMemo(() => {
    const classPeriodTeacherIds = selectedClassPeriodTeachers?.map(
      ({ schoolTeacherId }) => schoolTeacherId
    );

    return schoolTeachers?.filter(
      ({ schoolTeacherId }) => !classPeriodTeacherIds?.includes(schoolTeacherId)
    );
  }, [selectedClassPeriodTeachers, schoolTeachers]);

  const handleSubmitForm = async () => {
    const value = classPeriodForm.getFieldsValue();

    const classPeriodSchedules: ClassPeriodScheduleDto[] = value?.classPeriodSchedules?.map(
      (classPeriodSchedule: ClassPeriodScheduleDto) => {
        const start = dayjs(classPeriodSchedule?.startTime)?.format(
          'HH:mm:ssZZ'
        );
        const end = dayjs(classPeriodSchedule?.endTime)?.format('HH:mm:ssZZ');
        const startTime = start?.slice(0, start?.length - 2);
        const endTime = end?.slice(0, end?.length - 2);
        return {
          ...classPeriodSchedule,
          startTime,
          endTime
        };
      }
    );
    const classPeriodTeacherIds = selectedClassPeriodTeachers?.map(
      ({ schoolTeacherId }) => ({ schoolTeacherId })
    );

    try {
      if (classPeriodId) {
        const data = {
          subjectId: value?.subjectId,
          classPeriodName: value?.classPeriodName,
          classPeriodSchedules: classPeriodSchedules
        };

        const hasNewMainSchoolTeacher =
          mainSchoolTeacherId !== classPeriod?.mainSchoolTeacherId;

        const oldClassPeriodTeacherIds =
          classPeriod?.classPeriodTeachers?.map(({ schoolTeacherId }) => ({
            schoolTeacherId
          })) || [];

        const hasNewClassPeriodTeacherIds =
          classPeriodTeacherIds?.filter((newSchoolTeacherId) => {
            return oldClassPeriodTeacherIds?.every(
              (oldSchoolTeacherId) =>
                newSchoolTeacherId.schoolTeacherId !==
                oldSchoolTeacherId.schoolTeacherId
            );
          })?.length > 0;

        const hasDeletedClassPeriodTeacherIds =
          oldClassPeriodTeacherIds?.filter((oldSchoolTeacherId) => {
            return classPeriodTeacherIds?.every(
              (newSchoolTeacherId) =>
                newSchoolTeacherId.schoolTeacherId !==
                oldSchoolTeacherId.schoolTeacherId
            );
          })?.length > 0;

        if (
          hasNewMainSchoolTeacher ||
          hasNewClassPeriodTeacherIds ||
          hasDeletedClassPeriodTeacherIds
        ) {
          Object.assign(data, {
            mainSchoolTeacherId: mainSchoolTeacherId,
            classPeriodTeachers: classPeriodTeacherIds
          });
        }

        const {
          data: { data: classPeriodLicense }
        } = await updateClassPeriod({
          classPeriodId,
          data: {
            ...data
          }
        });
        onNext({
          classPeriodLicenseUsages: classPeriodLicense.licenseUsages
        });
      } else {
        const {
          data: { data: classPeriodLicense }
        } = await createClassPeriod({
          data: {
            classroomId: classroomId,
            subjectId: value?.subjectId,
            classPeriodName: value?.classPeriodName,
            mainSchoolTeacherId: mainSchoolTeacherId,
            classPeriodTeachers: classPeriodTeacherIds,
            classPeriodSchedules: classPeriodSchedules
          }
        });
        onNext({
          classPeriodLicenseUsages: classPeriodLicense.licenseUsages
        });
      }

      message.success(`${classPeriodId ? 'จัดการ' : 'เพิ่ม'}คาบสำเร็จ`);
    } catch (error) {
      var e = error as any;
      const { data: errorsResponse } = e?.response;

      let errorMessage =
        MANAGE_CLASS_PERIOD_ERROR_MESSAGE_MAPPER[errorsResponse.message];
      if (!errorMessage) {
        errorMessage = `${classPeriodId ? 'จัดการ' : 'เพิ่ม'}คาบไม่สำเร็จ`;
      }

      message.error(errorMessage);

      if (errorsResponse?.message === 'insufficient licenses') {
        onNext({
          classPeriodLicenseUsages: errorsResponse.errors,
          error: errorsResponse
        } as {
          classPeriodLicenseUsages: ClassPeriodModifyLicenseUsageDto[];
          error: ClassPeriodModifyResponse;
        });
      }
    }
  };

  const handleOnChangeSubject = (value: string) => {
    const subjectName =
      subjects.find((subject) => subject.subjectId === value)?.name.th ?? '';
    setCoverUrl(getCover(subjectName));
  };

  const loading =
    getSchoolTeachersLoading ||
    getClassPeriodLoading ||
    getSubjectsLoading ||
    deleting;

  return (
    <Spin spinning={loading}>
      <Global
        styles={css`
          .ant-form-item-label label {
            background-color: #fff !important;
          }
          .ant-spin-nested-loading,
          .ant-spin-container {
            position: static !important;
          }
        `}
      />
      <img
        alt="classroom cover"
        src={coverUrl}
        className="w-full object-cover"
        css={css`
          height: 264px;
          border-radius: 20px;
        `}
      />
      <Form
        form={classPeriodForm}
        onFinish={() => setIsConfirmationModalVisible(true)}
      >
        <Form.Item
          label="วิชา"
          name="subjectId"
          rules={[
            getRule(FormRule.REQUIRE, 'กรุณากรอกชื่อคาบเรียน'),
            ({ getFieldsValue }) => ({
              validator(rule, value) {
                const formValue = getFieldsValue(['subjectId']);
                const otherClassPeriodSubjects = classPeriods
                  .filter(
                    (classPeriod) => classPeriod.classPeriodId !== classPeriodId
                  )
                  .map((classPeriod) => classPeriod.subject.subjectId);
                if (
                  value &&
                  otherClassPeriodSubjects.includes(formValue?.subjectId)
                ) {
                  return Promise.reject(
                    'กรุณาเปลี่ยนวิชา เนื่องจากห้องเรียนนี้มีวิชานี้แล้ว '
                  );
                }

                return Promise.resolve();
              }
            })
          ]}
          className="mt-6"
        >
          <Select disabled={!!classPeriodId} onChange={handleOnChangeSubject}>
            {subjects?.map((subject) => (
              <Option value={subject?.subjectId} key={subject?.subjectId}>
                {subject?.name?.th}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="ชื่อคาบเรียน"
          name="classPeriodName"
          rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกชื่อคาบเรียน')]}
        >
          <Input className="rounded-xl h-10" />
        </Form.Item>
        <Divider />
        <div className="text-sm font-bold">ครูประจำวิชา</div>
        {/* unlock mainSchoolTeacher 19/08/2022 */}
        {/* {console.log("selectedClassPeriodTeachers.length",selectedClassPeriodTeachers.length)} */}
        {selectedClassPeriodTeachers?.length !== 0 && (
          <>
            <div className="text-sm font-medium text-gray-2 mt-2">ครูหลัก</div>
            <Form.Item
              name="mainSchoolTeacherId"
              rules={[getRule(FormRule.REQUIRE, 'กรุณาเลือกครูหลัก')]}
            >
              <Radio.Group
                className="w-full mt-2"
                value={mainSchoolTeacherId}
                onChange={(e) => {
                  setMainSchoolTeacherId(e?.target?.value);
                }}
              >
                {/* {console.log("selectedClassPeriodTeachers",selectedClassPeriodTeachers)} */}
                {selectedClassPeriodTeachers?.map(
                  ({
                    title,
                    firstName,
                    lastName,
                    schoolTeacherId,
                    deletedAt
                  }) => (
                    <Radio
                      className="flex w-full items-center mt-2"
                      value={schoolTeacherId}
                      key={schoolTeacherId}
                      css={css`
                        span.ant-radio + * {
                          width: 100%;
                          padding: 0 !important;
                          padding-left: 1rem !important;
                        }
                      `}
                    >
                      <Row align="middle">
                        <Col span={3}>
                          <img
                            src="/assets/profile-colour.png"
                            alt="classroomTeachers"
                            className="w-8 "
                          />
                        </Col>
                        <Col span={18}>
                          <div className="flex items-center whitespace-pre-line text-sm text-font-main overflow-ellipsis">
                            {/* unlock mainSchoolTeacher 19/08/2022 */}
                            {/* {console.log("deletedAt", deletedAt)} */}
                            {title + firstName?.th + ' ' + lastName?.th}&nbsp;
                            {/* {deletedAt ? (
                              <span style={{color:"red",fontWeight:"bold"}}>{' (ลาออกแล้ว)'}</span>
                            ) : null} */}
                            {deletedAt ? (
                              <span>{' (ย้ายโรงเรียน)'}</span>
                            ) : null}
                          </div>
                        </Col>
                        <Col className="flex justify-end" span={3}>
                          <DeleteIcon
                            className="cursor-pointer"
                            onClick={() => {
                              const filteredClassPeriodTeachers = selectedClassPeriodTeachers?.filter(
                                (classPeriodTeacher) =>
                                  classPeriodTeacher?.schoolTeacherId !==
                                  schoolTeacherId
                              );

                              setSelectedClassPeriodTeachers(
                                filteredClassPeriodTeachers
                              );
                              if (schoolTeacherId === mainSchoolTeacherId) {
                                if (selectedClassPeriodTeachers?.length > 1) {
                                  const newMainSchoolTeacher = selectedClassPeriodTeachers?.find(
                                    (newTeacher) =>
                                      schoolTeacherId !==
                                      newTeacher?.schoolTeacherId
                                  );

                                  setMainSchoolTeacherId(
                                    newMainSchoolTeacher?.schoolTeacherId || ''
                                  );
                                  classPeriodForm.setFieldsValue({
                                    mainSchoolTeacherId:
                                      newMainSchoolTeacher?.schoolTeacherId
                                  });
                                } else {
                                  setMainSchoolTeacherId('');
                                  classPeriodForm.setFieldsValue({
                                    mainSchoolTeacherId: undefined
                                  });
                                }
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </Radio>
                  )
                )}
              </Radio.Group>
            </Form.Item>
          </>
        )}
        <Form.Item
          className="mt-5"
          name="selectedSchoolTeacher"
          rules={[
            () => ({
              validator(_rule, _value) {
                if (selectedClassPeriodTeachers?.length === 0) {
                  return Promise.reject('กรุณาเลือกครู');
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Select
            placeholder="ค้นหาครูเพื่อเพิ่มในห้องเรียน"
            onSelect={(schoolTeacherId: string) => {
              if (selectedClassPeriodTeachers?.length === 0) {
                setMainSchoolTeacherId(schoolTeacherId);
                classPeriodForm.setFieldsValue({
                  mainSchoolTeacherId: schoolTeacherId
                });
              }
              const selectedTeacher = nonClassPeriodSchoolTeachers?.find(
                (nonClassPeriodTeacher) =>
                  nonClassPeriodTeacher?.schoolTeacherId === schoolTeacherId
              );
              if (selectedTeacher) {
                setSelectedClassPeriodTeachers([
                  ...selectedClassPeriodTeachers,
                  selectedTeacher
                ]);
              }
              classPeriodForm.setFieldsValue({
                selectedSchoolTeacher: null
              });
            }}
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) => {
              return (
                option?.text?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              );
            }}
          >
            {nonClassPeriodSchoolTeachers?.map(
              // // unlock mainSchoolTeacher 19/08/2022
              // ({ schoolTeacherId, title, firstName, lastName }) => (
              //   <Option key={schoolTeacherId} value={schoolTeacherId}>
              //     {title + firstName?.th + ' ' + lastName?.th}
              //   </Option>
              // )
              ({
                schoolTeacherId,
                title,
                firstName,
                lastName,
                email,
                isTemp
              }) => (
                <Option
                  key={schoolTeacherId}
                  value={schoolTeacherId}
                  text={`${title} + ${firstName?.th} + ' ' + ${lastName?.th}  ' ('+ ${email} +')'`}
                >
                  <div className="flex items-center">
                    <div className="my-0">
                      {' '}
                      {title + firstName?.th + ' ' + lastName?.th + '  (  '}
                    </div>
                    {isTemp ? (
                      <div className="mr-1"></div>
                    ) : (
                      <LogoIcon className="mr-1 w-4 h-4 ml-1" />
                    )}
                    <div className="my-0"> {email + '  )'}</div>
                  </div>
                </Option>
              )
            )}
          </Select>
        </Form.Item>
        <div className="text-sm">
          ไม่มีครูในระบบ?{' '}
          <span
            className="text-primary-blue cursor-pointer"
            onClick={() => history.push('/teacher-management')}
          >
            เพิ่มครู
          </span>
        </div>
        <Divider />
        <Form.List
          name="classPeriodSchedules"
          rules={getRule(FormRule.REQUIRE, 'กรุณาเพิ่มวันเวลาสอน')}
        >
          {(fields, { add, remove }) => {
            return (
              <Fragment>
                <div className="flex justify-end items-center">
                  <div
                    className="flex justify-end items-center cursor-pointer"
                    onClick={() => {
                      add();
                    }}
                  >
                    <PlusCircleOutlined className="mr-2" /> เพิ่มวันเวลาสอน
                  </div>
                </div>
                <div className="text-sm font-bold mb-4">วันเวลาสอน</div>
                {fields.length === 0 ? (
                  <div className="text-center mt-12">ยังไม่มีวันเวลาสอน</div>
                ) : (
                  fields?.map((field, index) => (
                    <Row
                      gutter={8}
                      className="mt-4 mx-0"
                      css={css`
                        .ant-form-item {
                          margin: 0 !important;
                        }
                      `}
                      key={field?.key}
                    >
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'dayOfWeek']}
                          fieldKey={[field.fieldKey, 'dayOfWeek']}
                          label="วัน"
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[getRule(FormRule.REQUIRE, 'กรุณาเลือกวัน')]}
                        >
                          <Select
                            css={css`
                              .ant-select-selector {
                                min-width: 0 !important;
                              }
                            `}
                            placeholder="วัน"
                          >
                            {map(DayOfWeek, (value) => (
                              <Option key={value} value={value}>
                                {DayOfWeekMapper[value]}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'startTime']}
                          fieldKey={[field.fieldKey, 'startTime']}
                          label="เริ่ม"
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            getRule(FormRule.REQUIRE, 'กรุณาเลือกเวลา'),
                            ({ getFieldsValue }) => ({
                              validator(rule, value) {
                                const formValue = getFieldsValue([
                                  'classPeriodSchedules'
                                ]);

                                if (
                                  value &&
                                  formValue?.classPeriodSchedules[index]
                                    ?.endTime &&
                                  !dayjs(value)?.isBefore(
                                    dayjs(
                                      formValue?.classPeriodSchedules[index]
                                        ?.endTime
                                    )
                                  )
                                ) {
                                  return Promise.reject('เวลาไม่ถูกต้อง');
                                }
                                return Promise.resolve();
                              }
                            })
                          ]}
                        >
                          <TimePicker
                            minuteStep={5}
                            format="HH:mm"
                            placeholder="เริ่ม"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={1}
                        className="relative"
                        css={css`
                          top: 10px;
                        `}
                      >
                        -
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'endTime']}
                          fieldKey={[field.fieldKey, 'endTime']}
                          label="สิ้นสุด"
                          validateTrigger={['onChange', 'onBlur', 'onFor']}
                          rules={[
                            getRule(FormRule.REQUIRE, 'กรุณาเลือกเวลา'),
                            ({ getFieldsValue }) => ({
                              validator(rule, value) {
                                const formValue = getFieldsValue([
                                  'classPeriodSchedules'
                                ]);

                                if (
                                  value &&
                                  formValue?.classPeriodSchedules[index]
                                    ?.startTime &&
                                  !dayjs(value)?.isAfter(
                                    dayjs(
                                      formValue?.classPeriodSchedules[index]
                                        ?.startTime
                                    )
                                  )
                                ) {
                                  return Promise.reject('เวลาไม่ถูกต้อง');
                                }
                                return Promise.resolve();
                              }
                            })
                          ]}
                        >
                          <TimePicker
                            minuteStep={5}
                            format="HH:mm"
                            placeholder="สิ้นสุด"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={3}
                        className="flex justify-center self-start"
                        css={css`
                          top: 10px;
                        `}
                      >
                        <DeleteIcon
                          className="cursor-pointer self-end"
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))
                )}
              </Fragment>
            );
          }}
        </Form.List>
        <div
          className={`mt-12 flex w-full ${
            classPeriodId ? 'justify-between' : 'justify-end'
          }`}
        >
          {classPeriodId && (
            <div
              className="flex font-bold cursor-pointer justify-start items-center"
              onClick={() => {
                setIsDeleteClassPeriodModalVisible(true);
              }}
            >
              <DeleteIcon className="mr-2" /> ลบคาบเรียน
            </div>
          )}
          <div className="flex">
            <SecondaryButton className="mx-4" onClick={onClose}>
              ยกเลิก
            </SecondaryButton>
            <PrimaryButton className="w-24" htmlType="submit">
              บันทึก
            </PrimaryButton>
          </div>
        </div>
      </Form>
      <Modal
        title="ยืนยันการลบคาบ ?"
        subTitle="คุณต้องการลบคาบเรียนนี้หรือไม่"
        visible={isDeleteClassPeriodModalVisible}
        cancel={{
          label: 'ยกเลิก',
          onClick: () => setIsDeleteClassPeriodModalVisible(false)
        }}
        submit={{
          label: 'ลบคาบเรียน',
          onClick: async () => {
            setIsDeleteClassPeriodModalVisible(false);
            await deleteClassPeriod(classPeriodId || '');
            classPeriodForm.resetFields();
            onClose();
          }
        }}
      />
      <Modal
        title={`ยืนยันการ${
          classPeriodId ? 'จัดการ' : 'เพิ่ม'
        }คาบและตัดสื่อเข้าคาบ ?`}
        subTitle="สื่อที่ตัดแล้วไม่สามารถคืนสิทธิ์ได้"
        visible={isConfirmationModalVisible}
        cancel={{
          label: 'ยกเลิก',
          onClick: () => setIsConfirmationModalVisible(false)
        }}
        submit={{
          label: 'ตกลง',
          loading: creating || updating,
          onClick: handleSubmitForm
        }}
      />
    </Spin>
  );
};
