/** @jsx jsx */
import { Button, Form, Input, Pagination, Table, message } from 'antd';
import {
  SCHOOL_ADMIN_ROLE_TYPE_MAPPER,
  SchoolAdminRoleType,
  SearchType
} from './school-admin-management';
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { ReactComponent as DeleteIcon } from 'assets/delete-icon.svg';
import { ReactComponent as EditIcon } from 'assets/edit-icon.svg';
import { ReactComponent as LogoIcon } from 'assets/logo.svg';
import { Modal } from 'feature/common/components/Modal';
import { SchoolAdminListDto } from '@aksorn/one-account-sdk';
import { SchoolAdminListSortBy } from 'feature/common/school-admin';
import { SchoolAdminManagementDrawer } from './SchoolAdminManagementDrawer';
import { SchoolAdminManagementSkeleton } from './SchoolAdminManagementSkeleton';
import { SearchOutlined } from '@ant-design/icons';
import { SortOrder } from 'feature/common/global';
import dayjs from 'dayjs';
import { useDeleteSchoolAdmin } from 'hooks/school-admin/useDeleteSchoolAdmins';
import { useSchoolAdmins } from 'hooks/school-admin/useSchoolAdmins';

type Props = {
  onEdit: (id: string) => void;
  onDeleting: (id: string) => void;
  setSortBy: (type: SchoolAdminListSortBy) => void;
  loading: boolean;
  dataSource: any;
};

const AdminTable = ({
  onEdit,
  onDeleting,
  setSortBy,
  dataSource,
  loading
}: Props) => {
  const columns: any = [
    {
      title: `รายชื่อผู้จัดการระบบ`,
      dataIndex: 'adminName',
      key: 'adminName',
      render: (_text: any, record: SchoolAdminListDto) => {
        const title = record.title;
        const firstName = record.firstName.th;
        const lastName = record.lastName.th;
        return (
          <div className="flex items-center">
            <img
              src="assets/profile-colour.png"
              alt="school-admin-profile"
              className="w-8 h-8 mr-2"
            />
            {`${title}${firstName} ${lastName}`}
          </div>
        );
      },
      sorter: (
        _a: SchoolAdminListDto,
        _b: SchoolAdminListDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolAdminListSortBy.NAME_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolAdminListSortBy.NAME_DESC);
            break;
          default:
            break;
        }
      }
    },
    {
      title: 'One account',
      dataIndex: 'email',
      key: 'email',
      sorter: (
        _a: SchoolAdminListDto,
        _b: SchoolAdminListDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolAdminListSortBy.EMAIL_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolAdminListSortBy.EMAIL_DESC);
            break;
          default:
            break;
        }
      },
      render: (email: string, record: SchoolAdminListDto) => {
        return (
          <div className="flex items-center">
            {record?.isTemp ? (
              <div className="mr-2 w-5 h-5" />
            ) : (
              <LogoIcon className="mr-2 w-5 h-5" />
            )}
            <div className="my-0">{email}</div>
          </div>
        );
      }
    },
    {
      title: 'บทบาท',
      dataIndex: 'schoolAdminRole',
      key: 'schoolAdminRole',
      width: '15%',
      render: (role: SchoolAdminRoleType) => (
        <div>{SCHOOL_ADMIN_ROLE_TYPE_MAPPER[role]}</div>
      )
    },
    {
      title: 'ใช้งานระบบจัดการล่าสุด',
      dataIndex: 'lastLoggedInAt',
      key: 'lastLoggedInAt',
      render: (lastLoggedInAt: string) => (
        <div>
          {lastLoggedInAt
            ? dayjs(lastLoggedInAt).add(543, 'year').format('DD/MM/YYYY')
            : '-'}
        </div>
      ),
      sorter: (
        _a: SchoolAdminListDto,
        _b: SchoolAdminListDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolAdminListSortBy.LAST_LOGGED_IN_AT_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolAdminListSortBy.LAST_LOGGED_IN_AT_DESC);
            break;
          default:
            break;
        }
      }
    },
    {
      render: (record: SchoolAdminListDto) => (
        <div className="flex space-x-8 items-center justify-center">
          <EditIcon
            className="cursor-pointer"
            onClick={() => onEdit(record.schoolAdminId)}
          />

          <DeleteIcon
            className="cursor-pointer"
            onClick={() => onDeleting(record.schoolAdminId)}
          />
        </div>
      )
    }
  ];

  return (
    <Table
      rowKey="schoolAdminId"
      loading={loading}
      pagination={false}
      dataSource={dataSource}
      columns={columns as any}
    />
  );
};

export const SchoolAdminManagement = () => {
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [keyword, setKeyword] = useState('');
  const [sortBy, setSortBy] = useState<SchoolAdminListSortBy>(
    SchoolAdminListSortBy.NAME_ASC
  );

  const [
    selectedSchoolAdmin,
    setSelectedSchoolAdmin
  ] = useState<SchoolAdminListDto>();
  const [
    isSchoolAdminManagementDrawerVisible,
    setIsSchoolAdminManagementDrawerVisible
  ] = useState(false);
  const [
    isDeletedSchoolAdminModalVisible,
    setIsDeletedSchoolAdminModalVisible
  ] = useState(false);

  const {
    data: schoolAdmins,
    loading: isFetchingSchoolAdmin,
    execute: fetchSchoolAdmins,
    total
  } = useSchoolAdmins();
  const { execute: deleteSchoolAdmin } = useDeleteSchoolAdmin();

  useEffect(() => {
    getSchoolAdmins({
      keyword: keyword,
      sortBy: sortBy,
      currentPage: currentPage
    });
  }, [keyword, sortBy, currentPage]);

  const [form] = Form.useForm();

  const getSchoolAdmins = async ({
    keyword,
    sortBy,
    currentPage
  }: {
    keyword: string;
    sortBy: SchoolAdminListSortBy;
    currentPage: number;
  }) => {
    try {
      await fetchSchoolAdmins({
        [SearchType.NAME]: keyword,
        sortBy: sortBy as any,
        pageNumber: currentPage
      });
    } catch (error) {
      message.error('ไม่สามารถโหลดรายชื่อผู้ดูแลระบบได้');
    }
  };

  const handleSearch = () => {
    setKeyword(searchText);
  };

  const handleDeleteSchoolAdmin = async () => {
    const hide = message.loading('กำลังดำเนินการ');
    try {
      if (selectedSchoolAdmin?.schoolAdminId) {
        await deleteSchoolAdmin(selectedSchoolAdmin?.schoolAdminId);
      }
      message.success('ลบบทบาทสำเร็จ');
      setSelectedSchoolAdmin(undefined);
      setIsDeletedSchoolAdminModalVisible(false);
      getSchoolAdmins({
        keyword: keyword,
        sortBy: sortBy,
        currentPage: currentPage
      });
    } catch (error) {
      if (
        error?.response?.data?.message === 'school needs at least one admin'
      ) {
        message.error(
          'ไม่สามารถลบบทบาทได้ เนื่องจากต้องมีผู้ดูแลระบบอย่างน้อย 1 คน'
        );
      } else {
        console.error(error);
        message.error('ลบผู้ดูแลระบบไม่สำเร็จ');
      }
    } finally {
      hide();
    }
  };

  return (
    <div>
      {isFetchingSchoolAdmin ? (
        <SchoolAdminManagementSkeleton />
      ) : (
        <div>
          <div className="flex justify-between items-center h-10 mb-8">
            <Form form={form}>
              <div className="flex items-center mt-6">
                <Form.Item>
                  <Input
                    className="w-80"
                    placeholder="ค้นหาจากรายชื่อหรืออีเมลผู้ดูแลระบบ"
                    suffix={<SearchOutlined onClick={handleSearch} />}
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    onPressEnter={handleSearch}
                  />
                </Form.Item>
              </div>
            </Form>
            <div className="flex items-center">
              <Button
                className="flex items-center text-base border-none font-bold text-white"
                css={css`
                  background-color: #f99d34;
                  :hover {
                    background-color: #fcbd10;
                  }
                  height: 40px;
                  width: 120px;
                  border-radius: 10px;
                `}
                onClick={() => {
                  setIsSchoolAdminManagementDrawerVisible(true);
                  setSelectedSchoolAdmin(undefined);
                }}
              >
                + เพิ่ม
              </Button>
            </div>
          </div>

          <AdminTable
            dataSource={schoolAdmins}
            loading={isFetchingSchoolAdmin}
            onDeleting={(id) => {
              const selectedAdmin = schoolAdmins?.find(
                ({ schoolAdminId }) => schoolAdminId === id
              );
              setSelectedSchoolAdmin(selectedAdmin);
              setIsDeletedSchoolAdminModalVisible(true);
            }}
            onEdit={(id) => {
              const selectedAdmin = schoolAdmins?.find(
                ({ schoolAdminId }) => schoolAdminId === id
              );
              setSelectedSchoolAdmin(selectedAdmin);
              setIsSchoolAdminManagementDrawerVisible(true);
            }}
            setSortBy={setSortBy}
          />
          {total && total > 0 && (
            <Pagination
              defaultPageSize={20}
              current={currentPage}
              showSizeChanger={false}
              total={total}
              onChange={(page: any) => {
                setCurrentPage(page);
              }}
            />
          )}
          <SchoolAdminManagementDrawer
            title={
              selectedSchoolAdmin ? 'แก้ไขสิทธิ์ผู้ดูแล' : 'เพิ่มสิทธิ์ผู้ดูแล'
            }
            visible={isSchoolAdminManagementDrawerVisible}
            schoolAdminId={selectedSchoolAdmin?.schoolAdminId}
            isEditing={!!selectedSchoolAdmin}
            onClose={() => {
              setIsSchoolAdminManagementDrawerVisible(false);
            }}
            refetch={() => {
              getSchoolAdmins({
                keyword: keyword,
                sortBy: sortBy,
                currentPage: currentPage
              });
            }}
          />
          <Modal
            visible={isDeletedSchoolAdminModalVisible}
            title="ถอนสิทธิ์ผู้ดูแลระบบ"
            submit={{
              label: 'ยืนยัน',
              onClick: handleDeleteSchoolAdmin
            }}
            cancel={{
              label: 'ยกเลิก',
              onClick: () => setIsDeletedSchoolAdminModalVisible(false)
            }}
          />
        </div>
      )}
    </div>
  );
};
