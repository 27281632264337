/** @jsx jsx */
import { Button, Form, Input, Pagination, Select, Table, message } from 'antd';
import {
  OutlinedButton,
  PrimaryButton
} from 'feature/common/components/Button';
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ManageSchoolTeacherInformationDrawer } from './ManageSchoolTeacherInformationDrawer';
import { Modal } from 'feature/common/components/Modal';
import { SchoolTeacherDto } from '@aksorn/teaching-backoffice-sdk';
import { SchoolTeacherSortBy } from 'feature/common/school-teacher';
import { SearchOutlined } from '@ant-design/icons';
import { SearchType } from './teacher-management';
import { SortOrder } from 'feature/common/global';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { default as logo } from '../../assets/logo.svg';
import { useSchoolTeacherTypeApprove } from 'hooks/school-teacher/useSchoolTeacherTypeApprove';
import { useSchoolTeacherTypeReject } from 'hooks/school-teacher/useSchoolTeacherTypeReject';
import { useSchoolTeachersType } from 'hooks/school-teacher/useSchoolTeachers';

const { Option } = Select;

type Props = {
  total: number;
  setSortBy: (type: SchoolTeacherSortBy | undefined) => void;
  onRejecting: (schoolTeacherId: string) => void;
  onApproving: (schoolTeacherId: string) => void;
  dataSource: any;
  loading: boolean;
};

const TeacherTable = ({
  total,
  setSortBy,
  onRejecting,
  onApproving,
  dataSource,
  loading
}: Props) => {
  const columns: any = [
    {
      title: `รายชื่อครู (${total})`,
      dataIndex: 'teacherName',
      key: 'teacherName',
      render: (text: any, record: SchoolTeacherDto) => {
        const firstName = record.firstName.th;
        const lastName = record.lastName.th;
        return (
          <div className="flex items-center">
            <img
              src="assets/profile-colour.png"
              alt="school-teacher-profile"
              className="w-8 h-8 mr-2"
            />
            {record.title + firstName + ' ' + lastName}
          </div>
        );
      },
      sorter: (
        a: SchoolTeacherDto,
        b: SchoolTeacherDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolTeacherSortBy.NAME_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolTeacherSortBy.NAME_DESC);
            break;
          default:
            break;
        }
      }
    },
    {
      title: 'One account',
      dataIndex: 'email',
      key: 'email',
      render: (email: string, record: SchoolTeacherDto) => {
        return (
          <div className="flex items-center">
            <img src={logo} className="mr-2 w-5 h-5" />{' '}
            <div className="my-0">{email}</div>
          </div>
        );
      },
      sorter: (
        a: SchoolTeacherDto,
        b: SchoolTeacherDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolTeacherSortBy.EMAIL_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolTeacherSortBy.EMAIL_DESC);
            break;
          default:
            break;
        }
      }
    },

    {
      title: '',
      render: (text: any, record: SchoolTeacherDto) => (
        <div className="flex space-x-4 items-center">
          <OutlinedButton
            onClick={() => {
              onRejecting(record.schoolTeacherId);
            }}
          >
            ปฏิเสธ
          </OutlinedButton>
          <PrimaryButton
            onClick={() => {
              onApproving(record.schoolTeacherId);
            }}
          >
            อนุมัติ
          </PrimaryButton>
        </div>
      )
    }
  ];
  return (
    <Table
      rowKey="schoolTeacherId"
      loading={loading}
      pagination={false}
      dataSource={dataSource}
      columns={columns as any}
      onChange={(pagination: any, filters: any, sorter: any) => {
        if (!sorter.order) setSortBy(undefined);
      }}
    />
  );
};

export const SchoolTeacherManagementType = () => {
  const [selectedUserId, setSelectedUserId] = useState('');
  const [type, setType] = useState<SearchType>(SearchType.NAME);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [keyword, setKeyword] = useState('');
  const [sortBy, setSortBy] = useState<SchoolTeacherSortBy>();
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);

  const {
    loading: approving,
    execute: approveSchoolTeacherType
  } = useSchoolTeacherTypeApprove();
  const {
    loading: rejecting,
    execute: rejectSchoolTeacherType
  } = useSchoolTeacherTypeReject();

  const {
    data: teachers,
    loading,
    total,
    execute: getSchoolTeachersType
  } = useSchoolTeachersType();

  useEffect(() => {
    getSchoolTeachersType({
      [type]: keyword,
      sortBy: sortBy as any,
      pageNumber: currentPage
    });
  }, [keyword, sortBy, currentPage]);

  const [form] = Form.useForm();

  const handleAcceptTeacher = async () => {
    const hide = message.loading('กำลังดำเนินการ');
    try {
      await approveSchoolTeacherType(selectedUserId);
      setSelectedUserId('');
      setOpenAcceptModal(false);
      getSchoolTeachersType({
        [type]: keyword,
        sortBy: sortBy as any,
        pageNumber: currentPage
      });
      message.success('ยืนยันการอนุมัติ');
    } catch (error) {
      message.error('อนุมัติไม่สำเร็จ');
    } finally {
      hide();
    }
  };
  const handleRejectTeacher = async () => {
    const hide = message.loading('กำลังดำเนินการ');
    try {
      await rejectSchoolTeacherType(selectedUserId);
      setSelectedUserId('');
      setOpenRejectModal(false);
      getSchoolTeachersType({
        [type]: keyword,
        sortBy: sortBy as any,
        pageNumber: currentPage
      });
      message.success('ปฏิเสธการเข้าระบบสำเร็จ');
    } catch (error) {
      message.error('ปฏิเสธการเข้าระบบไม่สำเร็จ');
    } finally {
      hide();
    }
  };

  const handleSearch = () => {
    setKeyword(searchText);
  };

  return (
    <div className="pt-6">
      <div className="flex items-center">
        <Form form={form}>
          <div className="flex items-center">
            {/* <Form.Item label="ประเภท">
              <Select
                css={css`
                  .ant-select-selector {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                  }
                `}
                value={type}
                onChange={(value: SearchType) => setType(value)}
              >
                <Option value={SearchType.NAME}>รายชื่อครู</Option>
                <Option value={SearchType.EMAIL}>One Account</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Input
                className="rounded-l-none w-72"
                placeholder="ค้นหาจากรายชื่อครู"
                suffix={<SearchOutlined onClick={handleSearch} />}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onPressEnter={handleSearch}
              />
            </Form.Item> */}
            <Form.Item>
              <Input
                className="w-72"
                placeholder="ค้นหาจากรายชื่อครู"
                suffix={<SearchOutlined onClick={handleSearch} />}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onPressEnter={handleSearch}
              />
            </Form.Item>
          </div>
        </Form>
      </div>

      <TeacherTable
        dataSource={teachers}
        loading={loading}
        setSortBy={setSortBy}
        total={total}
        onRejecting={(schoolTeacherId: string) => {
          setSelectedUserId(schoolTeacherId);
          setOpenRejectModal(true);
        }}
        onApproving={(schoolTeacherId: string) => {
          setSelectedUserId(schoolTeacherId);
          setOpenAcceptModal(true);
        }}
      />
      {total > 0 && (
        <Pagination
          defaultPageSize={20}
          current={currentPage}
          showSizeChanger={false}
          total={total}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      )}
      <Modal
        visible={openAcceptModal}
        title="ยืนยันการอนุมัติ"
        subTitle="คุณได้ตรวจสอบ และอนุมัติครูเพื่อใช้ระบบ"
        cancel={{
          onClick: () => setOpenAcceptModal(false),
          label: 'ยกเลิก'
        }}
        submit={{
          loading: approving,
          onClick: handleAcceptTeacher,
          label: 'ยืนยัน'
        }}
      />
      <Modal
        visible={openRejectModal}
        title="ปฏิเสธให้เข้าใช้ระบบ"
        subTitle="ต้องการปฏิเสธคุณครูไม่ให้เข้าใช้งานระบบ ?"
        cancel={{
          onClick: () => setOpenRejectModal(false),
          label: 'ยกเลิก'
        }}
        submit={{
          loading: rejecting,
          onClick: handleRejectTeacher,
          label: 'ปฏิเสธ'
        }}
      />
    </div>
  );
};
