import { Dropdown, Menu, Skeleton } from 'antd';
import { Global, css } from '@emotion/react';

import React from 'react';
import { ReactComponent as School } from 'assets/school.svg';
import env from '@beam-australia/react-env';
import { getUserDisplayName } from 'utils/getUserDisplayName';
import { logout } from 'api/user/logout';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { userStore } from 'stores/userStore';
import { Domain } from 'feature/common/global';

export const SchoolName = observer(() => {
  const { teachingProfile } = userStore;

  return !teachingProfile ? (
    <>
      <Global
        styles={css`
          .ant-skeleton,
          .ant-skeleton-button {
            width: 100% !important;
            vertical-align: sub;
          }
          .ant-skeleton-element .ant-skeleton-button-sm {
            height: 15px;
          }
        `}
      />
      <Skeleton.Button active size="small" shape="round" />
    </>
  ) : (
    <div className={teachingProfile.school.schoolName.th ? '' : 'text-red'}>
      {teachingProfile.school?.schoolName.th
        ? teachingProfile.school?.schoolName.th
        : 'ยังไม่ระบุโรงเรียน'}
    </div>
  );
});

export const NavbarProfileItem = observer(() => {
  const { i18n } = useTranslation();
  const { me } = userStore;
  console.log('me', me);

  return (
    <>
      <Global
        styles={css`
          .ant-dropdown {
            position: fixed;
            z-index: 9999 !important;
            /* top: 64px !important; */
            .ant-dropdown-menu {
              border-radius: 10px !important;
              padding: 0 !important;
              .ant-dropdown-menu-item:first-child {
                border-radius: 10px 10px 0 0 !important;
              }
              .ant-dropdown-menu-item:last-child {
                border-radius: 0 0 10px 10px !important;
              }
            }
            .ant-dropdown-menu-item {
              color: #5c5e70;
            }
            .ant-dropdown-menu-item-divider {
              margin: 0 !important;
            }
          }
        `}
      />
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="profile" className="px-4 py-2 text-base">
              <a
                className="text-secondary-gray-60"
                // href={!!me?.isTemp
                //   ? `${env('TEACHING_WEB_URL')}/profile`
                //   : `${env('TEACHING_WEB_URL')}/profile/update-profile?domain=school-admin`
                // }
                onClick={async () => {
                  if (me) {
                    const locationHostName =
                      window.location.protocol + '//' + window.location.host + '/';
                    const asPath = window.location.href
                      .replace(locationHostName, '')
                      .replace('&access=public', '');
                    const encodePath = encodeURIComponent(asPath);
                    if (me.isTemp) {
                      const encodeDomainAndRedirectPartQuery = encodeURIComponent(`domain=${Domain.SCHOOL_ADMIN}&r=${encodePath}`)
                      window.location = `${env('TEACHING_WEB_URL')}/profile?${encodeDomainAndRedirectPartQuery}` as any;
                    } else {
                      const encodeDomainAndRedirectPartQuery = encodeURIComponent(`domain=${Domain.SCHOOL_ADMIN}&redirectPath=${encodePath}`)
                      window.location = `${env('TEACHING_WEB_URL')}/profile/update-profile?${encodeDomainAndRedirectPartQuery}` as any;
                    }
                  }
                }}
              >
                โปรไฟล์ของฉัน
              </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="school-teacher" className="px-4 py-2 text-base">
              <a
                className="text-secondary-gray-60"
                href={`${env('TEACHING_WEB_URL')}/aksorn-on-learn`}
              >
                เปลี่ยนเป็นคุณครู
              </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key="logout"
              className="px-4 py-2 text-base text-secondary-gray-60"
              onClick={logout}
            >
              ออกจากระบบ
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <div
          className="flex items-center cursor-pointer transition-colors duration-500 ease-in-out px-4 py-2 my-2 text-secondary-gray-60 hover:bg-system-notification-grey rounded-xl"
          css={css`
            height: 49px;
          `}
        >
          <div className="flex items-center">
            <img
              alt="profile"
              className="w-5 h-5 lg:w-8 lg:h-8"
              src="/assets/account.svg"
              id="menu"
            />
            <div className="flex flex-col items-start justify-between mx-4">
              <h3 className="text-base font-bold lg:text-md my-0 text-secondary-gray-60">
                {getUserDisplayName(me, i18n)}
              </h3>

              <span className="text-xs w-full">
                <SchoolName />
              </span>
            </div>
          </div>
          <School className="ml-2" />
        </div>
      </Dropdown>
    </>
  );
});
